import {
  EditorOauthPopupCloseEvent,
  editorOauthPopupCloseEventType,
} from "src/pages/editor/popup-close";
import { PopupCloseEvent, popupCloseEventType } from "src/pages/popup-close";

export async function waitForPopupClose<ActionEvent, ActionType extends string>(
  popup: Window,
  actionType: ActionType,
): Promise<ActionEvent | undefined> {
  return await new Promise((resolve) => {
    function onMessage(event: MessageEvent): void {
      const action = event.data;
      if (action?.type === actionType) {
        popup.close();
        finishAndCleanUp(action);
      } else {
        // eslint-disable-next-line no-console
        console.warn("unknown action", action);
      }
    }

    function finishAndCleanUp(popupCloseEvent: ActionEvent | undefined): void {
      resolve(popupCloseEvent);
      removeEventListener("message", onMessage);
      clearInterval(interval);
    }

    // Wait for `postMessage` event
    addEventListener("message", onMessage);

    // Wait for popup to be closed if the message wasn't received
    const interval = setInterval(() => {
      if (popup.closed) {
        // The interval may have run after the popup closed and before onMessage had a chance to process messages.
        // This prevents a frequent race condition in Firefox, where finishAndCleanUp() was called here before onMessage
        // could call finishAndCleanUp(action).
        setTimeout(() => {
          finishAndCleanUp(undefined);
        });
      }
    });
  });
}

export async function waitForPopupCloseRegular(
  popup: Window,
): Promise<PopupCloseEvent | undefined> {
  return waitForPopupClose(popup, popupCloseEventType);
}

export async function waitForPopupCloseOauth(
  popup: Window,
): Promise<EditorOauthPopupCloseEvent | undefined> {
  return waitForPopupClose(popup, editorOauthPopupCloseEventType);
}
