import { useRouter } from "next/router";

/**
 * Returns the current value of the named query param, or undefined if it's not
 * present. Returns the last value if the param is specified more than once
 * (e.g. "?foo=a&foo=b" will give "b").
 *
 * NOTE: Due to how Next.js works, the page is rendered WITHOUT query params
 * once before its rendered with query params. That means this hook will always
 * return `undefined` at least once. Take care to use `useEffect` or similar to
 * watch for changes, rather than using the return as an initial value to
 * `useState`
 */
export function useQueryParam(name: string): string | undefined {
  const { query } = useRouter();
  return normalizeQueryParam(query[name]);
}

export function normalizeQueryParam(
  value: string | string[] | undefined,
): string | undefined {
  if (Array.isArray(value)) {
    return value[value.length - 1];
  }
  return value;
}
