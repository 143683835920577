/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from "@emotion/react";
import {
  Button,
  ContentSection,
  Heading,
  Spacer,
  Text,
} from "@zapier/design-system";
import { GetServerSideProps } from "next";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { IframePageLayout } from "src/components/IframePageLayout";
import { normalizeQueryParam, useQueryParam } from "src/utils/useQueryParam";
import { ZAPIER_PROXY_UPSTREAM } from "../../utils/env";

const CLIENT_ID = process.env.NEXT_PUBLIC_PARTNER_EDITOR_CLIENT_ID;
const ZAPIER_ORIGIN = process.env.NEXT_PUBLIC_ZAPIER_ORIGIN;

/**
 * This page is, on the client-side, exactly the same as the "main" popup-close
 * page. So, we re-use the same component to render the page.
 *
 * The only difference is that, on the backend, this /editor/popup-close page
 * exchanges the authorization code for an authorization token. The token is
 * then passed to the PopupClose page as a page prop
 */
async function exchangeAuthorizationCodeForAccessToken(
  authorizationCode: string,
): Promise<string> {
  const redirectUri = new URL("/partner/editor/popup-close", ZAPIER_ORIGIN); // Use the proxy so the client returns to the right origin

  const url = new URL(`/oauth/token/`, ZAPIER_PROXY_UPSTREAM); // Bypass the proxy for server to server communication

  const params = new URLSearchParams();
  params.append("grant_type", "authorization_code");
  params.append("code", authorizationCode);
  params.append("redirect_uri", redirectUri.href);
  params.append("client_id", CLIENT_ID || "");
  params.append("client_secret", process.env.WORKFLOW_API_CLIENT_SECRET || "");

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json",
    },
    body: params.toString(),
  });
  if (!response.ok) {
    throw new Error("Failed to fetch auth token");
  }
  const data = await response.json();
  return data.access_token;
}

export const getServerSideProps: GetServerSideProps<
  EditorPopupClosePageProps
> = async ({ query }) => {
  const props: EditorPopupClosePageProps = {};

  /**
   * If we get to this popup-close page with a `code` and a `state`,
   * then we'll exchange the authorization code for an auth token on the backend,
   * then pass the auth token to the client-side  as a page prop.
   */
  const code = normalizeQueryParam(query.code);
  if (code) {
    try {
      props.accessToken = await exchangeAuthorizationCodeForAccessToken(code);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  return { props };
};

const styleColumnCenter = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// This page is used to `postMessage` the `window.opener` to request to close
// the popup window. We use it as the `next` param for auth flow within a popup
// so that JS can automatically close the popup window instead of asking the
// user to do it. But, just in case the JS fails for whatever reason, we also
// display UI asking the user to close the popup.
export interface EditorPopupClosePageProps {
  accessToken?: string;
}

export const editorOauthPopupCloseEventType =
  "zapier.partner.editor.oauth.popup.close";
type EditorOauthPopupCloseEventType = typeof editorOauthPopupCloseEventType;

export interface EditorOauthPopupCloseEvent {
  type: EditorOauthPopupCloseEventType;
  state: string | undefined;
  accessToken: string | undefined;
}

export default function EditorPopupClose({
  accessToken,
}: EditorPopupClosePageProps) {
  const router = useRouter();
  const queryOauthState = useQueryParam("state");

  useEffect(() => {
    if (!(window.opener && router.isReady)) {
      return;
    }

    const message: EditorOauthPopupCloseEvent = {
      type: editorOauthPopupCloseEventType,
      accessToken,
      state: queryOauthState,
    };
    // Hey, before you think about changing `location.origin` to something else,
    // ask yourself "Is it safe to send that origin the access token?" It's
    // probably not. But hey, I'm just a message from the past ;) I can't stop
    // you.
    window.opener.postMessage(message, location.origin);

    // This currently seems to work in Chrome, Safari, and Firefox. Supposedly
    // a window shouldn't be able to close itself, only other windows opened
    // using `window.open()`, but that just doesn't seem to be true any more.
    // I swear some time in the past few years Firefox didn't support this
    // usage, but it's totally working now! Given that the COOP (cross origin
    // opener policy) prevents retaining access to this window, we must close
    // ourselves or else users will have to manually close the popup window...
    //
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/close
    //
    window.close();
  }, [router.isReady, queryOauthState, accessToken]);

  return (
    <IframePageLayout>
      <Head>
        <title>Close this page to continue | Zapier</title>
      </Head>
      <ContentSection>
        <Text tag={Heading} type="pageHeader7" textAlign="center">
          Close this page to continue
        </Text>
        <Spacer height={20} />
        <div css={styleColumnCenter}>
          <Button
            onClick={() => {
              window.close();
            }}
          >
            Continue
          </Button>
        </div>
      </ContentSection>
    </IframePageLayout>
  );
}
