/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import { Colors, ZapierLogo } from "@zapier/design-system";
import { ReactNode } from "react";

const cssPageContainer = css`
  background: ${Colors.neutral100};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const cssHeader = css`
  display: flex;
  border-bottom: 1px solid ${Colors.neutral300};
  min-height: 70px;
  box-sizing: border-box;
  align-items: center;
  padding: 15px;
`;

const cssMain = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: auto;
  padding-bottom: 70px;
`;

interface IframePageLayoutProps {
  children: ReactNode;
}

// Simplified page layout that just has a header with the Zapier logo and a
// neutral100 background
export function IframePageLayout({
  children,
}: IframePageLayoutProps): JSX.Element {
  return (
    <div css={cssPageContainer}>
      <header css={cssHeader}>
        <ZapierLogo height={22} width={81} />
      </header>
      <main css={cssMain}>{children}</main>
    </div>
  );
}
